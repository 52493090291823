import { contactSubmit } from './contact.js';
import { AutomatitCarousel } from './automatit_carousel.m';
import { multiMap } from './multiMap';
import './locations.js';

if(document.querySelector('#reviews')) {
	AutomatitCarousel({
		speed: 1000, 
		intervalTiming: false, 
		useChevrons: true, 
		easing: "easeInOutExpo", 
		showDots: false, 
		element: document.querySelector('#reviews')
	});
}
if(document.querySelector('#size_guide')) {
	AutomatitCarousel({
		speed: 1000, 
		intervalTiming: false, 
		useChevrons: true, 
		easing: "easeInOutExpo", 
		showDots: false, 
		element: document.querySelector('#size_guide')
	});
}
if(document.querySelector('#gallery')) {
	AutomatitCarousel({
		speed: 1000, 
		intervalTiming: false, 
		useChevrons: true, 
		easing: "easeInOutExpo", 
		showDots: false, 
		element: document.querySelector('#gallery')
	});
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}

if(document.querySelector('#map')) {
	multiMap();
}

/* eslint-disable */
$(function () {
    var forEach = function (t, o, r) {
        if ("[object Object]" === Object.prototype.toString.call(t)) for (var c in t) Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
        else for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t);
    };
    var hamburgers = document.querySelectorAll(".hamburger");
    if (hamburgers.length > 0) {
        forEach(hamburgers, function (hamburger) {
            hamburger.addEventListener(
                "click",
                function () {
                    this.classList.toggle("is-active");
                },
                false
            );
        });
    }
    $("#mobile_menu").click(function () {
        $("#navigation").toggleClass("active");
    });
    $("#navigation .close").click(function () {
        $("#navigation").animate({ right: "109%" }, 500);
    });
    if ($(window).width() > 930) {
        $("#navigation li:has(ul)").doubleTapToGo();
    }
    $(".btn_pay").click(function () {
        $("#pay-bill, #overlay").addClass("active");
    });
    $("#overlay").click(function () {
        $("#pay-bill, #overlay").removeClass("active");
    });
});

(function ($, window, document, undefined) {
    $.fn.doubleTapToGo = function (params) {
        if (!("ontouchstart" in window) && !navigator.msMaxTouchPoints && !navigator.userAgent.toLowerCase().match(/windows phone os 7/i)) return false;
        this.each(function () {
            var curItem = false;
            $(this).on("click", function (e) {
                var item = $(this);
                if (item[0] != curItem[0]) {
                    e.preventDefault();
                    curItem = item;
                }
            });
            $(document).on("click touchstart MSPointerDown", function (e) {
                var resetItem = true,
                    parents = $(e.target).parents();
                for (var i = 0; i < parents.length; i++) if (parents[i] == curItem[0]) resetItem = false;
                if (resetItem) curItem = false;
            });
        });
        return this;
    };
})(jQuery, window, document);