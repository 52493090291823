/* eslint-disable */

if(document.querySelector('#facility_header')) {

	var filterUnits = function(event, ui) {
		var min = ui.values[0];
		var max = ui.values[1];
		var none = $('#units_none');
		var units = $('.unit_row');
		none.hide();
		units.hide();
		units = units.filter(function() {
			var sqft = $(this).data('sqft');
			if(max < 300)
			{
				return sqft >= min && sqft <= max;
			}
			return sqft >= min;
		});
		if(units.length) {
			units.show();
		} else {
			none.show();
		}
	};
	$(function() {
		var reviews_init = 0;
		var link = window.location.href;
		link = link.split('#');
		if(link[1] == "rev" && reviews_init == 0){
			var fac_reviews = {
				speed: 1000,
				intervalTiming: 0,
				useChevrons: true,
				easing: 'easeInOutExpo',
				showDots: false,
				afterSlide: function(prev, current) {}
			};
			$('#fac_reviews').automatitCarousel(fac_reviews);
			reviews_init = 1;
		}
		$('.abs_tab_wrap').absolutelyTabulous();
		/* Tabs */
		$('.abs_tab_nav_tab').click(function(){
			// setTimeout(function(){
			// 	google.maps.event.trigger(myMapGlobal, 'resize');
			// 	myMapGlobal.setCenter(myLatlng);
			// 	myMapGlobal.fitBounds(myBoundsGlobal);
			// }, 400);
			if($(this).html() == "Reviews" && reviews_init == 0){
				console.log(link[1]);
				setTimeout(function(){
					var fac_reviews = {
						speed: 1000,
						intervalTiming: 0,
						useChevrons: true,
						easing: 'easeInOutExpo',
						showDots: false,
						afterSlide: function(prev, current) {}
					};
					$('#fac_reviews').automatitCarousel(fac_reviews);
				}, 400);
	
				reviews_init = 1;
			}
		});
	
		/* Unit Filter Slider */
		$( "#slider" ).slider({
			range: true,
			min: 0,
			max: 300,
			values: [ 0, 300 ],
			slide: filterUnits,
			change: filterUnits
		});
		$('.slider_trigger, .slider_size').click(function(){
			var min = $(this).data('min');
			var max = $(this).data('max');
			$('#slider').slider('values', [min, max]);
			return false;
		});
	
		/* Image Gallery */
		var speed = 200;
		$('.facility-image').click(function() {
			var height = $('.main_img').filter(':visible').height();
			var src = $(this).prop('src');
			$('#facility-image-container').css('height', height);
			$('.main_img').filter(':visible').fadeOut(speed, function() {
				$('.main_img').prop('src', src);
				$('.main_img').fadeIn(speed, function() {
					height = $(this).height();
					$('#facility-image-container').animate({height: height}, speed);
				});
			});
			return false;
		});
	
	});
}

